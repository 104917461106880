import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer'
import Layout from '../layouts'

class AboutUs extends Component {
    render() {       
        return (
            <Fragment>
                <Layout className="customer-footer-wrap">
                    <div class="sub-pages-container mb-auto">
                        <div>
                            <h1>About Us</h1>
                            <p>Fairfest is a leading media company providing platforms for doing business in one of the
                                fastest growing markets in the world - India.
                            </p>
                            <p>Since 1989, Fairfest has been organizing industry-leading tradeshows in the travel and city
                                development sectors.
                            </p>
                        </div>
                        <div>
                            <h3> Our work is effectively divided into three main segments:
                            </h3>
                            <div>
                            <ol>
                                <li> <a href="https://fairfest.in/travel-and-tourism">TRAVEL & TOURISM</a></li>
                                <ul>
                                    <li> TRADESHOWS | PUBLICATIONS</li>
                                </ul>
                                <li><a href="https://fairfest.in/urban-development">URBAN DEVELOPMENT</a></li>
                                <ul>
                                    <li> TRADESHOWS | PUBLICATIONS</li>
                                </ul>
                                <li><a href="https://fairfest.in/technology">TECHNOLOGY</a></li>
                                <ul>
                                    <li> AMBITIOUS PRODUCTS ACROSS DIGITAL MEDIA, CONSUMER TECH AND BEYOND
                                    </li>
                                </ul>
                            </ol>
                        </div>
                        </div>
                    </div>
                    <Footer />
                </Layout>
            </Fragment>
        )
    }
}
export default AboutUs